import React from "react"
import Layout from "../components/layout"

function OmOss() {
    return (
        <Layout>
            <div>Galleri</div>
        </Layout>
    )
    
    
}

export default OmOss